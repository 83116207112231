import { useCallback, useEffect, useState } from 'react';
import { Box, Card, TextField, Typography } from '@mui/material';
import { DataCard } from '../components/DataCard';
import * as calculations from '../calculations/prices';

export const InputViewNew = () => {

    // SHOW LARGE TABLES IN A USERFRIENDLY WAY
    // https://www.youtube.com/watch?v=s7nvF1PuAWY&ab_channel=NNgroup


    // https://preisatlas-api.homeday.de/latest/property_price?address=neusser+str.+15,+52353+dueren,+deutschland&property_type=apartment&marketing_type=rent&map_layer=standard

    const [angebotspreis, setAngebotspreis] = useState(100000);
    const [eigenKapital, setEigenkapital] = useState(10000);
    const [quadratmeter, setQuadratmeter] = useState(30);
    const [miete, setMiete] = useState(0);
    const [hausgeld, setHausgeld] = useState(0);
    const [durchschnittsMieteProQuadratmeter, setDurchschnittsmieteProQuadratmeter] = useState(11);
    const [zinssatz, setZinssatz] = useState(4.0);
    const [tilgungssatz, setTilgungssatz] = useState(2.0);

    const [kaufData, setKaufData] = useState([]);
    const [finanzierungData, setFinanzierungData] = useState([]);
    const [einnahmenData, setEinnahmenData] = useState([]);
    const [renditeData, setRenditeData] = useState([]);
    const [cashflowData, setCashflowData] = useState([]);

    const getMiete = useCallback(() => {

        let tmpRent = miete;
        if (!miete || miete === 0) {
            tmpRent = quadratmeter * durchschnittsMieteProQuadratmeter;
        }
        return tmpRent;
    }, [miete, quadratmeter, durchschnittsMieteProQuadratmeter]);

    const calculate = useCallback(async () => {
        calculateAndSet(() => {
            return calculations.purchasePrice(angebotspreis);
        }, setKaufData);

        calculateAndSet(() => {
            return calculations.loan(angebotspreis, eigenKapital, zinssatz, tilgungssatz);
        }, setFinanzierungData);

        calculateAndSet(() => {
            return calculations.income(getMiete(), quadratmeter, durchschnittsMieteProQuadratmeter);
        }, setEinnahmenData);

        calculateAndSet(() => {
            return calculations.rentalYield(quadratmeter, getMiete(), angebotspreis, hausgeld);
        }, setRenditeData);

        calculateAndSet(() => {
            return calculations.cashflow(angebotspreis, eigenKapital, getMiete(), quadratmeter, zinssatz, tilgungssatz, hausgeld);
        }, setCashflowData);

    }, [getMiete, angebotspreis, durchschnittsMieteProQuadratmeter, eigenKapital, hausgeld, quadratmeter, tilgungssatz, zinssatz]);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        if (queryParameters.size === 0) {
            return;
        }

        const kaufPreis = queryParameters.get("kaufpreis");
        if (kaufPreis) {
            setAngebotspreis(Number(kaufPreis));
        }

        const hausgeld = queryParameters.get("hausgeld");
        if (hausgeld) {
            setHausgeld(parseFloat(hausgeld));
        }

        const wohnflaeche = queryParameters.get("wohnflaeche");
        console.log("wohnflaeche", wohnflaeche);
        console.log("hausgeld", hausgeld);
        if (wohnflaeche) {
            setQuadratmeter(parseFloat(wohnflaeche));
        }
    }, [])

    useEffect(() => {
        calculate();
    }, [calculate, angebotspreis, eigenKapital, quadratmeter, miete, hausgeld, durchschnittsMieteProQuadratmeter, zinssatz, tilgungssatz]);

    const calculateAndSet = (calcFunc, setFunc) => {
        const result = calcFunc();
        setFunc(result);
    }

    return (
        <div>
            <div style={{padding: 20}}>
                <Card sx={{ display: 'flex', flexDirection: 'column', width: '70%', marginLeft: 'auto', marginRight: 'auto', minWidth: 1100 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', padding: 2 }}>
                        <Typography className='input-container-title'>
                            Preise
                        </Typography>
                        <TextField sx={{ margin: '0 10px 0 10px' }} type='number' label="Angebotspreis" variant="outlined" value={angebotspreis} onChange={e => setAngebotspreis(parseInt(e.target.value))} />
                        <TextField sx={{ margin: '0 10px 0 10px' }} type='number' label="Eigenkapital" variant="outlined" value={eigenKapital} onChange={e => setEigenkapital(parseInt(e.target.value))} />
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', padding: 2 }}>
                        <Typography className='input-container-title' >
                            Wohnung
                        </Typography>
                        <TextField sx={{ margin: '0 10px 0 10px' }} type='number' inputProps={{ step: "0.1", min: 0.1 }} label="Quadratmeter" variant="outlined" value={quadratmeter} onChange={e => setQuadratmeter(parseFloat(e.target.value))} />
                        <TextField sx={{ margin: '0 10px 0 10px' }} type='number' inputProps={{ step: "0.1", min: 0.1 }} label="DurchschnittsmieteProQuadratmeter" variant="outlined" value={durchschnittsMieteProQuadratmeter} onChange={e => setDurchschnittsmieteProQuadratmeter(parseFloat(e.target.value))} />
                        <TextField sx={{ margin: '0 10px 0 10px' }} type='number' label="Nettomiete/Monat" variant="outlined" value={miete} onChange={e => setMiete(parseInt(e.target.value))} />
                        <TextField sx={{ margin: '0 10px 0 10px' }} type='number' label="Hausgeld" variant="outlined" value={hausgeld} onChange={e => setHausgeld(parseInt(e.target.value))} />
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', padding: 2 }}>
                        <Typography className='input-container-title'>
                            Kredit
                        </Typography>
                        <TextField sx={{ margin: '0 10px 0 10px' }} type="number" inputProps={{ step: "0.1", min: 0.1 }} label="Zinssatz" variant="outlined" value={zinssatz} onChange={e => setZinssatz(parseFloat(e.target.value))} />
                        <TextField sx={{ margin: '0 10px 0 10px' }} type="number" inputProps={{ step: "0.1", min: 0.1 }} label="Tilgungssatz" variant="outlined" value={tilgungssatz} onChange={e => setTilgungssatz(parseFloat(e.target.value))} />
                    </Box>

                </Card>
            </div>
            {/* <div className="info-cards">
                <Card sx={{ display: 'flex', flexDirection: 'row', padding: 2, margin: 2 }}>
                    <Typography paddingTop={2}>
                        Preise
                    </Typography>
                    <TextField type='number' label="Angebotspreis" variant="outlined" value={angebotspreis} onChange={e => setAngebotspreis(parseInt(e.target.value))} />
                    <TextField type='number' label="Eigenkapital" variant="outlined" value={eigenKapital} onChange={e => setEigenkapital(parseInt(e.target.value))} />
                </Card>

                <Card sx={{ display: 'flex', flexDirection: 'row', padding: 2, margin: 2 }}>
                    <Typography paddingTop={2}>
                        Wohnung
                    </Typography>
                    <TextField type='number' inputProps={{ step: "0.1", min: 0.1 }} label="Quadratmeter" variant="outlined" value={quadratmeter} onChange={e => setQuadratmeter(parseFloat(e.target.value))} />
                    <TextField type='number' inputProps={{ step: "0.1", min: 0.1 }} label="DurchschnittsmieteProQuadratmeter" variant="outlined" value={durchschnittsMieteProQuadratmeter} onChange={e => setDurchschnittsmieteProQuadratmeter(parseFloat(e.target.value))} />
                    <TextField type='number' label="Nettomiete/Monat" variant="outlined" value={miete} onChange={e => setMiete(parseInt(e.target.value))} />
                    <TextField type='number' label="Hausgeld" variant="outlined" value={hausgeld} onChange={e => setHausgeld(parseInt(e.target.value))} />
                </Card>

                <Card sx={{ display: 'flex', flexDirection: 'row', padding: 2, margin: 2 }}>
                    <Typography paddingTop={2}>
                        Kredit
                    </Typography>
                    <TextField type="number" inputProps={{ step: "0.1", min: 0.1 }} label="Zinssatz" variant="outlined" value={zinssatz} onChange={e => setZinssatz(parseFloat(e.target.value))} />
                    <TextField type="number" inputProps={{ step: "0.1", min: 0.1 }} label="Tilgungssatz" variant="outlined" value={tilgungssatz} onChange={e => setTilgungssatz(parseFloat(e.target.value))} />
                </Card>
            </div> */}

            {/* <div style={{ display: 'flex', flexDirection: 'column', margin: '3rem' }}>



            </div> */}
            {/* <Button
                variant="contained"
                size="large"
                onClick={calculate}
            >
                Calculate
            </Button>
            */}

            <div style={{ display: 'flex' }}>
                <DataCard title='Kauf' data={kaufData} />
                <DataCard title='Finanzierung' data={finanzierungData} />
                <DataCard title='Einnahmen' data={einnahmenData} />
            </div>
            <div style={{ display: 'flex' }}>
                <DataCard title='Mietrendite' data={renditeData} />
                <DataCard title='Cashflow' data={cashflowData} />
            </div>
        </div>

    );
}