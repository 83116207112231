import { formatGermanNumber } from '../helper/helper';
import * as additionalPurchaseCosts from '../calculators/additionalPurchaseCosts';
import * as loanCalcs from '../calculators/loan';
import * as rentalYieldCalcs from '../calculators/rentalYield';

export const purchasePrice = (askingPrice) => {
    const result = [];

    result.push({
        key: 'Kaufpreis',
        value: `${formatGermanNumber(askingPrice)}€`
    })
    result.push({
        key: `Grunderwerbsteuer (${additionalPurchaseCosts.propertyTransferTaxPercent}%)`,
        value: `${formatGermanNumber(additionalPurchaseCosts.getPropertyTransferTax(askingPrice))}€`
    })
    result.push({
        key: `Notar und Grundbuchkosten (${additionalPurchaseCosts.notaryCostsPercent}%)`,
        value: `${formatGermanNumber(additionalPurchaseCosts.getNotaryCosts(askingPrice))}€`
    })
    result.push({
        key: `Maklergebühren (${additionalPurchaseCosts.brokerCostsPercent}%)`,
        value: `${formatGermanNumber(additionalPurchaseCosts.getBrokerCosts(askingPrice))}€`
    })
    result.push({
        key: `Ankaufnebenkosten (${additionalPurchaseCosts.getAcquisitionAdditionalCostsPercent()}%)`,
        value: `${formatGermanNumber(additionalPurchaseCosts.getAcquisitionAdditionalCosts(askingPrice))}€`
    })
    result.push({
        key: 'Gesamtkosten',
        value: `${formatGermanNumber(askingPrice + additionalPurchaseCosts.getAcquisitionAdditionalCosts(askingPrice))}€`,
        highlight: true,
    })
    // result['Wertanteil Grundstück'] = 'Keine Ahnung' // TODO
    // result['Renovierungskosten'] = 'Keine Ahnung' // TODO
    // result['Einbauküche'] = 'Keine Ahnung' // TODO
    // result['Externe Garage'] = 'Keine Ahnung' // TODO

    return result;
}

export const loan = (askingPrice, equity, interestRate, repaymentRate) => {
    const result = [];

    const totalCosts = askingPrice + additionalPurchaseCosts.getAcquisitionAdditionalCosts(askingPrice);
    const creditAmount = loanCalcs.creditAmount(askingPrice, equity);
    const annuity = loanCalcs.annuity(creditAmount, interestRate, repaymentRate);
    const creditPercent = creditAmount / totalCosts * 100;
    const monthlyInstallment = loanCalcs.monthlyInstallmentFromAnnuity(annuity);

    result.push({
        key: 'Eigenkapital',
        value: `${formatGermanNumber(equity)}€`
    })
    result.push({
        key: `Darlehen (${creditPercent.toFixed(2)}%)`,
        value: `${formatGermanNumber(creditAmount)}€`
    })
    result.push({
        key: `Zinssatz`,
        value: `${formatGermanNumber(interestRate)}%`
    })
    result.push({
        key: `Anfängliche Tilgung`,
        value: `${formatGermanNumber(repaymentRate)}%`
    })
    result.push({
        key: `Annuität`,
        value: `${formatGermanNumber(annuity)}€`
    })
    result.push({
        key: `Kreditrate`,
        value: `${formatGermanNumber(monthlyInstallment)}€`
    })

    return result;
}

export const income = (rent, squareMeters, averageRentPerSquareMeter) => {

    const result = [];

    // TODO: put this into calculations?
    const netRentPerSquareMeter = rent / squareMeters;

    result.push({
        key: 'Miete',
        value: `${formatGermanNumber(rent)}€`
    })
    result.push({
        key: `Netto Kaltmiete / qm`,
        value: `${formatGermanNumber(netRentPerSquareMeter)}€`
    })
    result.push({
        key: `Durchschnitts Kaltmiete / qm`,
        value: `${formatGermanNumber(averageRentPerSquareMeter)}€`
    })

    return result;
}

export const rentalYield = (squareMeters, rent, askingPrice, commonCharge) => {

    const result = [];

    const minAverageCommonCharge = 2.5 * squareMeters;
    const maxAverageCommonCharge = 4 * squareMeters;

    commonCharge = getCommonCharge(commonCharge, minAverageCommonCharge, maxAverageCommonCharge);

    const annualRent = rent * 12.0;
    const nonAllocableCosts = commonCharge / 2;
    const netAnnualRent = (rent - nonAllocableCosts) * 12;
    const totalAcquisitionCosts = askingPrice + additionalPurchaseCosts.getAcquisitionAdditionalCosts(askingPrice);
    const grossRentalYield = rentalYieldCalcs.grossRentalYield(annualRent, askingPrice);
    const netRentalYield = rentalYieldCalcs.netRentalYield(netAnnualRent, totalAcquisitionCosts);

    result.push({
        key: 'Durchschnitts Hausgeld (2,50€ - 4€ / qm)',
        value: `${formatGermanNumber(minAverageCommonCharge)}€ - ${formatGermanNumber(maxAverageCommonCharge)}€`
    })
    result.push({
        key: 'Nicht umlegbare Kosten (50% vom Hausgeld)',
        value: `${formatGermanNumber(nonAllocableCosts)}€`
    })
    result.push({
        key: 'Mietrendite (Brutto)',
        value: `${formatGermanNumber(grossRentalYield)}%`
    })
    result.push({
        key: 'Mietrendite (Netto)',
        value: `${formatGermanNumber(netRentalYield)}%`
    })
    result.push({
        key: 'Kaufpreisfaktor (Brutto)',
        value: `${formatGermanNumber(100 / grossRentalYield)}`
    })
    result.push({
        key: 'Kaufpreisfaktor (Netto)',
        value: `${formatGermanNumber(100 / netRentalYield)}`
    })

    return result;
}

const getCommonCharge = (commonCharge, minAverageCommonCharge, maxAverageCommonCharge) => { 
    let tmpCommonCharge = commonCharge;
    if (!commonCharge || commonCharge === 0) {
        tmpCommonCharge = (minAverageCommonCharge + maxAverageCommonCharge) / 2;
    }
    return tmpCommonCharge
};

export const cashflow = (askingPrice, equity, rent, squareMeters, interestRate, repaymentRate, commonCharge) => {

    const result = [];

    const creditAmount = loanCalcs.creditAmount(askingPrice, equity);
    const annuity = loanCalcs.annuity(creditAmount, interestRate, repaymentRate);
    const monthlyInstallment = loanCalcs.monthlyInstallmentFromAnnuity(annuity);

    const minAverageCommonCharge = 2.5 * squareMeters;
    const maxAverageCommonCharge = 4 * squareMeters;

    commonCharge = getCommonCharge(commonCharge, minAverageCommonCharge, maxAverageCommonCharge);

    const nonAllocableCosts = commonCharge / 2;

    const cashflow = rent-monthlyInstallment-nonAllocableCosts

    result.push({
        key: 'Kaltmiete',
        value: `${formatGermanNumber(rent)}€`
    })
    result.push({
        key: '- Kreditrate',
        value: `${formatGermanNumber(monthlyInstallment)}€`
    })  
    result.push({
        key: '- Hausgeld (nicht umlegbar)',
        value: `${formatGermanNumber(nonAllocableCosts)}€`
    })
    // result.push({
    //     key: '- Instandhaltungsrücklage WEG',
    //     value: `${formatGermanNumber(maintenanceReserve)}€`
    // })
    result.push({
        key: 'Cashflow (Brutto)',
        value: `${formatGermanNumber(cashflow)}€`,
        highlight: true,
        color: (cashflow > 0) ? 'chartreuse' : 'orangered', // chartreuse, forestGreen, lightgreen, lightcoral, orangered 
    })

    return result;
}
