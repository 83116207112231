export const propertyTransferTaxPercent = 6.5;
export const notaryCostsPercent = 1.5;
export const brokerCostsPercent = 3.22;

export function getAcquisitionAdditionalCosts(askingPrice) {
  return getAcquisitionAdditionalCostsPercent() * askingPrice / 100;
}

export function getPropertyTransferTax(askingPrice) {
  return askingPrice*propertyTransferTaxPercent / 100;
}

export function getNotaryCosts(askingPrice) {
  return askingPrice*notaryCostsPercent / 100;
}

export function getBrokerCosts(askingPrice) {
  return askingPrice*brokerCostsPercent / 100;
}

export function getAcquisitionAdditionalCostsPercent() {
  return propertyTransferTaxPercent + notaryCostsPercent + brokerCostsPercent;
}

export function percentOfaskingPrice(askingPrice, percent) {
  return askingPrice * percent / 100;
}