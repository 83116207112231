import './App.css';
import { InputViewNew } from './views/InputViewNew';

function App() {
  return (
    <div className="App">
      <InputViewNew />
    </div>
  );
}

export default App;
