import { Card, Typography } from "@mui/material";

export const DataCard = (props) => {
    return (
        <Card sx={{padding: 3, width: '30%', maxWidth: '30%', margin: 2}}>
            <Typography variant="h5" paddingBottom={2}>
                {props.title}
            </Typography>
            {props.data.map((data, idx) => (
                <div 
                className={`data-card-item`+(data.highlight ? ' highlight' : ''+(data.color ? ' '+data.color : ''))}
                style={{backgroundColor: data.color ? data.color : ''}}
                key={`kauf-${idx}`}>
                    <span className="data-card-key">{data.key}:</span>
                    <span className="data-card-value">{data.value}</span>
                </div>
            ))}
        </Card>
    )
};