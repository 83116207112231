import {getAcquisitionAdditionalCosts} from './additionalPurchaseCosts';

export function creditAmount(askingPrice, equity) {
    return askingPrice + getAcquisitionAdditionalCosts(askingPrice) - equity;
}

export function interestAmountPerYear(creditAmount, interestRate) {
    return creditAmount * interestRate / 100.0;
}

export function interestAmountPerMonth(creditAmount, interestRate) {
    return interestAmountPerYear(creditAmount, interestRate) / 12.0;
}

export function repaymentAmountPerYear(creditAmount, repaymentRate) {
    return creditAmount * repaymentRate / 100.0;
}

export function repaymentAmountPerMonth(creditAmount, repaymentRate) {
    return repaymentAmountPerYear(creditAmount, repaymentRate) / 12.0;
}

export function monthlyInstallment(repaymentAmountPerMonth, interestAmountPerMonth) {
    return repaymentAmountPerMonth + interestAmountPerMonth;
}

export function monthlyInstallmentFromAnnuity(annuity) {
    return annuity / 12.0;
}

export function annuity(creditAmount, interestRate, repaymentRate) {
    return creditAmount * ((interestRate + repaymentRate) / 100.0);
}

export function remainingDebtAfterYears(creditAmount, monthlyInstallment, interestRate, termInYears) {
    let remainingDebt = creditAmount;

    for (let i = 0; i < ((termInYears * 12) + 1); i++) {
        let interestAmount = remainingDebt * (interestRate / 100.0) / 12.0;
        let repayment = monthlyInstallment - interestAmount;

        remainingDebt = remainingDebt - repayment;
    }

    return remainingDebt;
}

export function creditTerm(creditAmount, monthlyInstallment, interestRate) {
    let months = 0;
    let remainingDebt = creditAmount;

    while (true) {
        let interestAmount = remainingDebt * (interestRate / 100.0) / 12.0;
        let repayment = monthlyInstallment - interestAmount;
        remainingDebt = remainingDebt - repayment;

        months++;

        if (remainingDebt <= 0) {
            break;
        }
    }

    return [Math.floor(months / 12), months % 12];
}